<template>
  <div class="w-full">
    <label
      class="flex w-full flex-row text-sm font-medium leading-6 text-gray-900"
      :for="id"
    >
      <span class="flex-1">
        {{ label }}
      </span>
      <slot name="label" />
    </label>
    <div class="mt-2">
      <input
        :id="id"
        :class="{
          'block w-full rounded-md border border-gray-300 shadow-sm placeholder:text-gray-400 focus:ring-4 focus:ring-blue-300 sm:text-sm sm:leading-6': true,
          'py-1.5 lg:px-4 lg:py-3 lg:text-lg': true,
        }"
        :data-id="dataId"
        :required="required"
        :placeholder="placeholder"
        :name="id"
        :minlength="minlength"
        :type="type"
        :value="modelValue"
        :tabindex="tabindex"
        :autocomplete="autocomplete ? autocomplete : undefined"
        @change="onChange"
        @input="onChange"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
const emit = defineEmits<{
  (e: 'update:modelValue', p: string): void;
}>();
const onChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  emit('update:modelValue', target.value);
};
withDefaults(
  defineProps<{
    tabindex?: number;
    modelValue: string;
    id: string;
    type: string;
    minlength?: number | string;
    label: string;
    autocomplete?: string;
    placeholder?: string;
    required?: boolean;
    dataId: string;
  }>(),
  {
    tabindex: undefined,
    autocomplete: '',
    minlength: undefined,
    placeholder: '',
    modelValue: '',
    required: false,
  },
);
</script>
